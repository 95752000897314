import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appLinkify]'
})
export class LinkifyDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.linkifyText();
  }

  private linkifyText() {
    const text = this.el.nativeElement.innerHTML;
    if (text.includes('https://') && !text.includes('https://rep-agakhan.dataly.gr')) {
      const replacedText = text.replace(/https:\/\/[^\s]+/g, (match) => `<a href="${match}" target="_blank">${match}</a>`);
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', replacedText);
    }
  }
}
