<div class="btm-header-wrapper">
  <div class="container">
    <section class="logo-and-navigation-tabs-wrapper">
      <div class="navigation-tabs-wrapper">
        <div class="social-media-icons-wrapper">
          <a target="_blank" href="https://www.facebook.com/akuismclondon/">
            <div class="facebook-icon"></div>
          </a>
          <a target="_blank" href="https://twitter.com/agakhanlibrary">
            <div class="twitter-icon"></div>
          </a>
        </div>

        <div *ngFor="let tab of navigationTabs; first as isFirst"
            class="navigation-tab"
            [class.stylish-line]="!isFirst"
            [routerLink]="tab.path"
            routerLinkActive="navigation-tab-is-active">
            {{ tab.value }}
        </div>

        <div *ngIf="!(isMobile$ | async)" class="logo-wrapper">
          <img
            alt="Logo"
            class="logo"
            src="../../assets/images/header/AKL_Logo_350.png"
            (click)="goToExternalLink(baseUrl)" />
        </div>
      </div>
    </section>

    <section *ngIf="(isMobile$ | async)" class="text-center">
      <img
        alt="Logo"
        class="logo"
        src="../../assets/images/header/AKL_Logo_350.png"
        (click)="goToExternalLink(baseUrl)" />

      <br>
    </section>

    <section class="address-copyright-wrapper" [class.address-copyright-wrapper-mobile]="(isMobile$ | async)">
      <div>
        Aga Khan Library | 10 Handyside St | London N1C 4DN | library-services@iis.ac.uk
      </div>
      <a (click)="goToExternalLink(baseUrl)">
        © {{ year }} Aga Khan Library
      </a>
    </section>
  </div>
</div>

