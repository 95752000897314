<div
  [ngClass]="isStack ? 'stack-simple-view-wrapper' : 'simple-view-wrapper'"
  [class.remove-border-bottom]="isLastInStack"
  [class.d-none]="hideIfNoTextContent && content.textContent.trim().length === 0">
  <div
    *ngIf="label"
    [ngClass]="isStack ? 'stack-simple-view-item-header' : 'simple-view-item-header'">
    {{ label }}:
  </div>
  <div #content class="simple-view-item-body">
    <div appLinkify>
      <ng-content></ng-content>
    </div>
  </div>
</div>
